<template>
  <div>
    <b-table
      id="my-table"
      ref="table"
      :no-provider-sorting="true"
      :items="fetchData"
      :fields="tableColumns"
      :current-page="currentPage"
      :per-page="perPage"
      aria-busy="true"
      class="position-relative"
      responsive="lg"
      show-empty
      empty-text="No events found"
      primary-key="id"
    >
      <template #cell(date)="{item}">
        <b-media vertical-align="center">
          <b-link
            class="font-weight-bold d-block text-nowrap"
          >
            {{ item.start_time }}
          </b-link>
        </b-media>
      </template>
      <!-- Column: Title -->
      <template #cell(title)="{item}">
        {{ item.activity_name }}
      </template>

      <template #cell(sessions)="{item}">
        {{ item.sessions_count }} Sessions
      </template>
      <!--eslint-disable vue/no-unused-vars -->
      <template
        #cell(registered)="{item}"
      >
        {{ calculateRegisteredMembers(item) }}
      </template>

      <template #cell(notes)="{item}">
        {{ item.activity_description ? item.activity_description.slice(0, 50) : '' }}
      </template>

      <template #cell(actions)="{ item }">
        <b-dropdown
          id="download-prayers-options"
          variant="link"
          no-caret
        >
          <template #button-content>
            <feather-icon
              icon="MoreVerticalIcon"
              size="16"
              class="align-middle text-body"
            />
          </template>
          <b-dropdown-item :to="{ name: 'prayer-bookings-view', params:{ id: item.id } }">
            <feather-icon icon="FileTextIcon" />
            <span class="align-middle ml-50">View</span>
          </b-dropdown-item>

          <b-dropdown-item @click="chooseExportEventMode(item.id)">
            <feather-icon icon="FileTextIcon" />
            <span class="align-middle ml-50">Export Event</span>
          </b-dropdown-item>

          <b-dropdown-item :to="{ name: 'prayer-bookings-edit', params:{ id: item.id } }">
            <feather-icon icon="EditIcon" />
            <span> Edit </span>
          </b-dropdown-item>

          <b-dropdown-item
            @click="deleteRecord(deleteEndPoint,item.id,
                                 deleteMessage(calculateRegisteredMembers(item)))"
          >
            <feather-icon icon="TrashIcon" />
            <span class="align-middle ml-50">Delete</span>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-table>
  </div>
</template>
<script>
import { saveAs } from 'file-saver'
import deleteEntityComposition from '@/common/compositions/common/deleteRecord'
import handleAlerts from '@/common/compositions/common/handleAlerts'

export default {
  name: 'PrayerBookingsTable',
  props: {
    fetchData: {
      type: Function,
      default: () => [],
    },
    isBusy: {
      type: Boolean,
      default: true,
    },
    currentPage: {
      type: Number,
      default: 1,
    },
    perPage: {
      type: Number,
      default: 1,
    },
  },
  setup() {
    const { confirmExportMode } = handleAlerts()
    const {
      deleteRecord,
      table,
    } = deleteEntityComposition()

    return {
      deleteRecord,
      table,
      confirmExportMode,
    }
  },
  data() {
    return {
      tableColumns: [
        {
          key: 'date',
          sortable: true,
        },
        {
          key: 'title',
          sortable: true,
        },
        {
          key: 'sessions',
          sortable: true,
        },
        {
          key: 'registered',
          sortable: true,
        },
        {
          key: 'notes',
          sortable: true,
        },
        { key: 'actions' },
      ],
      deleteEndPoint: `${process.env.VUE_APP_ACTIVITIES_BASE_URL}/internalops/delete_activity/`,
    }
  },
  methods: {
    chooseExportEventMode(id) {
      this.confirmExportMode().then(mode => {
        this.exportEventById(id, mode)
      })
    },
    exportEventById(id, mode) {
      this.$activities.get(`/internalops/prayer-activities/export/${id}?withDetails=${mode}`).then(res => {
        setTimeout(() => { this.downloadEventExcelFile(res.data.file) }, 2000)
      })
    },
    downloadEventExcelFile(filename) {
      this.$activities.get(`/internalops/prayer-activities/download/${filename}`, {
        responseType: 'blob',
      }).then(res => {
        const blob = new Blob([res.data])
        saveAs(blob, filename)
      }).catch(() => {
        setTimeout(() => { this.downloadEventExcelFile(filename) }, 5000)
      })
    },
    deleteMessage(membersCount) {
      return membersCount ? 'Session has active booked users, Do you need to delete' : null
    },
    calculateRegisteredMembers(item) {
      if (item.sessions.length) {
        const reducer = (accumulator, currentValue) => accumulator + currentValue.booking_details_count
        return item.sessions.reduce(reducer, 0)
      }

      return 0
    },
  },
}
</script>
<style lang="scss" scoped>

</style>
