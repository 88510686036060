var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-table',{ref:"table",staticClass:"position-relative",attrs:{"id":"my-table","no-provider-sorting":true,"items":_vm.fetchData,"fields":_vm.tableColumns,"current-page":_vm.currentPage,"per-page":_vm.perPage,"aria-busy":"true","responsive":"lg","show-empty":"","empty-text":"No events found","primary-key":"id"},scopedSlots:_vm._u([{key:"cell(date)",fn:function(ref){
var item = ref.item;
return [_c('b-media',{attrs:{"vertical-align":"center"}},[_c('b-link',{staticClass:"font-weight-bold d-block text-nowrap"},[_vm._v(" "+_vm._s(item.start_time)+" ")])],1)]}},{key:"cell(title)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.activity_name)+" ")]}},{key:"cell(sessions)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.sessions_count)+" Sessions ")]}},{key:"cell(registered)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.calculateRegisteredMembers(item))+" ")]}},{key:"cell(notes)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.activity_description ? item.activity_description.slice(0, 50) : '')+" ")]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('b-dropdown',{attrs:{"id":"download-prayers-options","variant":"link","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-middle text-body",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"to":{ name: 'prayer-bookings-view', params:{ id: item.id } }}},[_c('feather-icon',{attrs:{"icon":"FileTextIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("View")])],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.chooseExportEventMode(item.id)}}},[_c('feather-icon',{attrs:{"icon":"FileTextIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Export Event")])],1),_c('b-dropdown-item',{attrs:{"to":{ name: 'prayer-bookings-edit', params:{ id: item.id } }}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}}),_c('span',[_vm._v(" Edit ")])],1),_c('b-dropdown-item',{on:{"click":function($event){_vm.deleteRecord(_vm.deleteEndPoint,item.id,
                               _vm.deleteMessage(_vm.calculateRegisteredMembers(item)))}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Delete")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }